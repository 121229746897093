import styled from "styled-components";
import about_logo_big from "../../../assets/Home/About/about_logo_big.png";
import about_logo_small from "../../../assets/Home/About/about_logo_small.png";
import about_bg from "../../../assets/Home/About/about_bg.png";
import {
  NoodlesChoptickContainer,
  NoodlesChoptickWrapper,
} from "../../../components/Common/Noodles/styled";

export const AboutContainer = styled.div`
  position: relative;
  /* height: 900px; */
  background-color: #323232;
  margin-top: -85px;
  overflow: hidden;
  @media screen and (max-width: 1023px) {
    margin-top: 0;
    height: auto;
  }
`;
export const AboutWrapper = styled.div`
  position: relative;
  /* min-height: 100vh; */
  &::before {
    content: "";
    position: absolute;
    background: url(${about_bg}) no-repeat center / cover;
    width: 100%;
    height: 100%;
    top: 100px;
    pointer-events: none;
  }
  @media screen and (max-width: 1023px) {
    min-height: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    /* height: 1070px; */
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 0px;
  }
`;
export const AboutMarquee = styled.div`
  padding: 30px;
  margin-bottom: 70px;
  background-color: #323232;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 1023px) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
`;
export const AboutMarqueeItem = styled.div`
  margin: 0 20px;
  cursor: pointer;
`;
export const AboutInner = styled.div`
  position: relative;
  max-width: 1510px;
  margin: 0 auto;
  padding: 0 15px;
  /* min-height: calc(100vh - 175px); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    animation: AboutLogoRotate 5s linear infinite;
  }
  &::before {
    width: 140px;
    height: 140px;
    right: 0;
    top: -80px;
    background-image: url(${about_logo_big});
  }
  &::after {
    left: -100px;
    top: 150px;
    width: 94px;
    height: 94px;
    background-image: url(${about_logo_small});
  }
  @keyframes AboutLogoRotate {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @media screen and (max-width: 1023px) {
    padding: 0 10px;
    &::before,
    &::after {
      content: none;
    }
  }
`;
export const AboutContent = styled.div`
  position: relative;
  font-family: "Leckerli One", cursive;
  font-size: 24px;
  color: #fff;
  text-align: center;
  width: max-content;
  margin: 0 auto 25px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 50%;
    margin-top: -1px;
    width: 132px;
  }
  &::before {
    left: 240px;
  }
  &::after {
    right: 240px;
  }
  @media screen and (max-width: 767px) {
    &::before,
    &::after {
      width: 42px;
    }
    &::before {
      left: 230px;
    }
    &::after {
      right: 230px;
    }
  }
`;
export const AboutDescription = styled.div`
  color: #fff;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 90px;
  p {
    max-width: 743px;
    margin: 0px auto;
  }
  .big {
    font-size: 48px;
  }
  .color {
    color: #e72d36;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 50px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 30px;
    margin-bottom: 40px;
    .big {
      font-size: 30px;
    }
  }
`;
export const AboutGooseRead = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;
  /* aspect-ratio: 1090/445; */
  @media only screen and (max-width: 768px) {
    padding-bottom: 40px;
  }
`;
export const AboutDecor = styled.div`
  /* position: absolute; */
  /* width: 33.2%; */
  left: 0px;
  bottom: 0;
  pointer-events: none;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    left: 0;
  }
`;
export const AboutNood = styled.div`
  /* width: 31.5%; */
  height: calc(100% - 105px);
  position: absolute;
  bottom: 0px;
  right: 0px;
  aspect-ratio: 455 / 607;
  background-color: aliceblue;
`;
export const AboutNoodWapper = styled.div``;
export const AboutNoodles = styled.div`
  position: absolute;
  bottom: -50px;
  right: 200px;
  z-index: 0;
  ${NoodlesChoptickContainer} {
    ${NoodlesChoptickWrapper} {
      transform: rotate(-90deg) translateY(125px);
      & > span {
        &.flavors {
          left: 540px;
          width: 340px;
          height: 206px;
        }
        &.bowl {
          width: 150px;
          height: 334px;
        }
        &.noodles {
          width: 160px;
          height: 344px;
        }
        &.chopsticks {
          width: 1366px;
          height: 272px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    right: 0;
    ${NoodlesChoptickWrapper} {
      transform: rotate(-90deg) translateY(225px);
    }
  }
`;
