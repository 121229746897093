import styled from "styled-components";

export const TitleCommonContainer = styled.div`
  span {
    position: relative;
    display: block;
    margin: 0 auto 15px;
    width: max-content;
    font-family: "Leckerli One", cursive;
    font-size: 24px;
    &::before,
    &::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 132px;
      top: 50%;
      margin-top: 0;
    }
    &::before {
      left: -155px;
    }
    &::after {
      right: -155px;
    }
  }
  h2 {
    text-align: center;
    text-shadow: 0px 8px 0px #000;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #fff;
    font-size: 71px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.7px;
  }
  @media screen and (max-width: 767px) {
    span {
      font-size: 20px;
      margin-bottom: 10px;
      &::before,
      &::after {
        width: 42px;
      }
      &::before {
        left: -55px;
      }
      &::after {
        right: -55px;
      }
    }
    h2 {
      font-size: 45px;
    }
  }
`;
export const SubTitleCommon = styled.span<{ subTitleColor: string }>`
  color: ${({ subTitleColor }) => subTitleColor};
  &::before,
  &::after {
    background-color: ${({ subTitleColor }) => subTitleColor};
  }
`;
export const TitleCommonMain = styled.h2<{ titleColor: string }>`
  color: ${({ titleColor }) => titleColor};
`;
