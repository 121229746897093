import TitleCommon from "../../../components/Common/Title";
import { BlockLeft, ContentBlock } from "../HowToBuy/styled";
import GrowWave from "./Wave";
import { GrowContainer, GrowContent, GrowWrapper } from "./styled";
import grow_img from "../../../assets/Home/Grow/grow_img.png";
import NoodlesChoptick from "../../../components/Common/Noodles";
import { useContext } from "react";
import { ContextProviderWrapper } from "../../../components/Context";

const Grow = ({ chopsticksTranslate }: any) => {
  const { isMobile, isDesktop } = useContext(ContextProviderWrapper)!;
  return (
    <GrowContainer id="cook-the-culture">
      {!isMobile && <GrowWave background='#FFC715' />}
      <GrowWrapper>
        <TitleCommon
          title="Cook The Culture"
          subTitle="Grow with Us"
          titleColor="#7ec900"
          subTitleColor="#000"
        />
        <GrowContent>
          <div>
            <ContentBlock>
              <BlockLeft>
                <h3>Poong Master Chef</h3>
                <p>
                  With a mission to craft the finest Avalanche Culture dish,
                  Master Chef Poong is stirring up a storm, concocting unique
                  noodles and perfecting his craft with unparalleled skill.
                  Poong is eagerly awaited to bring forth the utmost culinary
                  delights for eagerly enthusiasts of taste experiences.
                </p>
              </BlockLeft>
            </ContentBlock>
            <ContentBlock>
              <BlockLeft>
                <h3>How can he be?</h3>
                <ul>
                  <li>he has Skillful cooking</li>
                  <li>
                    Top smart anatidae to know what’s the right thing with your
                    money
                  </li>
                  <li>There is no dish he can't cook</li>
                  <li>Have sturdy wings, cook the Culture</li>
                </ul>
              </BlockLeft>
            </ContentBlock>
          </div>
          <figure>
            <img src={grow_img} alt="img" />
          </figure>
        </GrowContent>
      </GrowWrapper>
      {isDesktop && (
        <NoodlesChoptick chopsticksTranslate={chopsticksTranslate} />
      )}
    </GrowContainer>
  );
};

export default Grow;
