import Marquee from "react-fast-marquee";
import {
  AboutContainer,
  AboutContent,
  AboutDecor,
  AboutDescription,
  AboutGooseRead,
  AboutInner,
  AboutMarquee,
  AboutMarqueeItem,
  AboutNood,
  AboutNoodles,
  AboutWrapper,
} from "./styled";
import marquee_1 from "../../../assets/Home/About/marquee_1.png";
import marquee_2 from "../../../assets/Home/About/marquee_2.png";
import marquee_3 from "../../../assets/Home/About/marquee_3.png";
import marquee_4 from "../../../assets/Home/About/marquee_4.png";
import marquee_5 from "../../../assets/Home/About/marquee_5.png";
import goose_about from "../../../assets/Home/About/goose_about.png";
import { useContext, useRef, useState } from "react";
import NoodlesChoptick from "../../../components/Common/Noodles";
import { useMotionValueEvent, useScroll } from "framer-motion";
import GooseKick from "../../../components/Common/GooseKick";
import { ContextProviderWrapper } from "../../../components/Context";

const About = () => {
  const { isMobile, isDesktop } = useContext(ContextProviderWrapper)!;
  const scrollableDivRef = useRef<any>(null);
  const [trackMouseY, setTrackMouseY] = useState(0);
  const handleMouseMove = (event: any) => {
    setTrackMouseY(event.clientY - 100);
  };
  const { scrollYProgress } = useScroll({
    target: scrollableDivRef,
  });

  useMotionValueEvent(scrollYProgress, "change", (value: any) => {});

  return (
    <AboutContainer
      id="about"
      ref={scrollableDivRef}
      onMouseMove={handleMouseMove}
    >
      <AboutWrapper>
        <AboutMarquee>
          <Marquee>
            {marqueeData.map((item: any, index: number) => (
              <AboutMarqueeItem key={index}>
                <img src={item} alt="marquee" />
              </AboutMarqueeItem>
            ))}
          </Marquee>
        </AboutMarquee>
        <AboutInner>
          <div>
            <AboutContent>
              <h2>The Chef is here</h2>
            </AboutContent>
            <AboutDescription>
              <p>
                POONG is a Master Chef in the{" "}
                <span className="color">AVAX</span> Area. Cooking the Avalanche
                Culture with community noodle from the talent of Poong.
              </p>
            </AboutDescription>
          </div>
          <AboutGooseRead>
            {!isMobile && (
              <AboutDecor>
                <GooseKick />
              </AboutDecor>
            )}
            <img src={goose_about} alt="goose_about" />
            {!isMobile && (
              <AboutNoodles>
                <NoodlesChoptick trackMouseY={isDesktop ? trackMouseY : 500} />
              </AboutNoodles>
            )}
          </AboutGooseRead>
        </AboutInner>
        {/* <AboutNood></AboutNood> */}
      </AboutWrapper>
    </AboutContainer>
  );
};

const marqueeData = [
  marquee_1,
  marquee_2,
  marquee_3,
  marquee_4,
  marquee_5,
  marquee_1,
  marquee_2,
  marquee_3,
  marquee_4,
  marquee_5,
  marquee_1,
  marquee_2,
  marquee_3,
  marquee_4,
  marquee_5,
];

export default About;
