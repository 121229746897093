import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  ButtonAudit,
  ButtonConnectWallet,
  ButtonHamburger,
  HeaderAction,
  HeaderBody,
  HeaderContainer,
  HeaderContent,
  HeaderInner,
  HeaderLinks,
  HeaderLogo,
  MobileAction,
} from "./styled";
import logo from "../../assets/Header/logo.png";
import { Link, useLocation } from "react-router-dom";
import { ContextProviderWrapper } from "../Context";
import { useContext, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";
import { shortenAddress } from "../../utils/addressUser";
import { ButtonCommon } from "../../Layout/styled";

const Header = () => {
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const [openHamburger, setOpenHamburger] = useState(false);
  const handleOpenHamburger = () => {
    setOpenHamburger(!openHamburger);
  };
  const { pathname } = useLocation();

  useEffect(() => {
    if (openHamburger) {
      document.body.classList.add("hidden");
    } else {
      document.body.classList.remove("hidden");
    }
  }, [openHamburger]);

  const truncateAddress =
    address &&
    `${address.substr(0, 4)}...${address.substr(address.length - 4)}`;

  return (
    <HeaderBody home={pathname === "/"}>
      <HeaderContainer>
        <HeaderLogo>
          <Link to="/">
            <img loading="lazy" src={logo} alt="logo" />
          </Link>
        </HeaderLogo>
        <HeaderContent
          style={
            !isDesktop && openHamburger ? { transform: "translateX(0)" } : {}
          }
          className={!isDesktop ? "menu-fixed" : ""}
        >
          <HeaderInner>
            <HeaderLinks>
              {linkData.map((item, index) => {
                if (item.scrollTo) {
                  return (
                    <li key={index}>
                      <HashLink
                        smooth
                        to={item.href}
                        onClick={() => {
                          setOpenHamburger(false);
                        }}
                      >
                        {item.title}
                      </HashLink>
                    </li>
                  );
                } else {
                  return (
                    <li>
                      {item.externalLink ? (
                        <>
                          <a target="_blank" rel="noreferrer" href={item.href}>
                            {item.title}
                          </a>
                        </>
                      ) : (
                        <>
                          <NavLink
                            onClick={() => setOpenHamburger(false)}
                            to={item.href}
                          >
                            {item.title}
                          </NavLink>
                        </>
                      )}
                    </li>
                  );
                }
              })}
            </HeaderLinks>
          </HeaderInner>
          <HeaderAction>
            <ButtonCommon
              to="#"
              color="#000"
              background="#FFC715"
              onClick={() => open()}
            >
              {address ? <p>{shortenAddress(address)}</p> : <p>Buy $PoonG</p>}
            </ButtonCommon>
          </HeaderAction>
        </HeaderContent>
        {!isDesktop && (
          <MobileAction>
            <ButtonHamburger
              className={openHamburger ? "active" : ""}
              onClick={() => {
                handleOpenHamburger();
              }}
            >
              <span></span>
              <span></span>
            </ButtonHamburger>
          </MobileAction>
        )}
      </HeaderContainer>
    </HeaderBody>
  );
};

const linkData = [
  {
    title: "Home",
    href: "/#home",
    scrollTo: true,
    externalLink: false,
  },
  {
    title: "About",
    href: "/#about",
    scrollTo: true,
    externalLink: false,
  },
  {
    title: "Cook The Culture",
    href: "/#cook-the-culture",
    scrollTo: true,
    externalLink: false,
  },
  {
    title: "How To Buy",
    href: "/#howtobuy",
    scrollTo: true,
    externalLink: false,
  },
  // {
  //   title: "AIRDROP",
  //   href: "/airdrop",
  //   externalLink: false,
  // },
  // {
  //   title: "Docs",
  //   href: "#",
  //   scrollTo: false,
  //   externalLink: true,
  // },
];

export default Header;
