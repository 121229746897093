import { useState } from "react";
import { FooterActions } from "../styled";
import { Link } from "react-router-dom";
import twitter from "../../../assets/Footer/twitter.png";
import telegram from "../../../assets/Footer/telegram.png";

const CommunityAction = () => {
  const [mountState, setMountState] = useState(false);
  return (
    <FooterActions className={mountState ? "mountState" : ""}>
      <div
        onMouseEnter={() => {
          setMountState(true);
        }}
        onMouseLeave={() => {
          setMountState(false);
        }}
      >
        <Link
          to="https://twitter.com/PoongAvax"
          target="_blank"
          rel="noreferrer"
          className="icon-social"
        >
          <img src={twitter} alt="twitter" />
        </Link>
      </div>
      <div
        onMouseEnter={() => {
          setMountState(true);
        }}
        onMouseLeave={() => {
          setMountState(false);
        }}
      >
        <Link to="https://t.me/PoongAvax" target="_blank" rel="noreferrer" className="icon-social">
          <img src={telegram} alt="telegram" />
        </Link>
      </div>
    </FooterActions>
  );
};

export default CommunityAction;
