import { useContext, useEffect, useRef, useState } from "react";
import About from "./About";
import Grow from "./Grow";
import HowToBuy from "./HowToBuy";
import Keyvisual from "./Keyvisual";
import Nomics from "./Nomics";
import { HomeContainer } from "./styled";
import { ContextProviderWrapper } from "../../components/Context";
import { useMotionValueEvent, useScroll } from "framer-motion";
import {
  KeyvisualNotification,
  NotificationClose,
  NotificationContent,
} from "./Keyvisual/styled";
import CommunityAction from "../../components/Footer/Action";

const Home = () => {
  const { isDesktop } = useContext(ContextProviderWrapper)!;
  const ref = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["0.01 start", "1 .63"],
  });

  const [chopsticksTranslate, setChopsticksTranslate] = useState<any>(0);
  useMotionValueEvent(scrollYProgress, "change", (value: any) => {
    if (value * 120 >= 52) {
      setChopsticksTranslate(chopsticksTranslate);
    } else {
      setChopsticksTranslate(value * 120);
    }
  });

  const [closeNotification, setCloseNotification] = useState(false);
  const [translateNotification, setTranslateNotification] = useState(false);
  useEffect(() => {
    if (!translateNotification) {
      setTimeout(() => {
        setTranslateNotification(true);
      }, 1000);
    }
  }, []);

  return (
    <HomeContainer id="home">
      {/* {isDesktop && ( */}
        {/* <KeyvisualNotification
          className={
            closeNotification && translateNotification
              ? "hide"
              : translateNotification && !closeNotification
              ? "hide appear"
              : ""
          }
        >
          <NotificationClose
            onClick={() => {
              setCloseNotification(true);
            }}
          >
            X
          </NotificationClose>
          <NotificationContent>
            <p>Join poong community</p>
            <p>
              click on eyes icon to join poong
              <br /> community
            </p>
          </NotificationContent>
          <CommunityAction />
        </KeyvisualNotification> */}
      {/* )} */}
      <Keyvisual />
      <div ref={ref}>
        <About />
        <Grow chopsticksTranslate={chopsticksTranslate} />
        <Nomics />
        <HowToBuy />
      </div>
    </HomeContainer>
  );
};

export default Home;
