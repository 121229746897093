import {
  FooterContainer,
  FooterContent,
  FooterCopyRight,
  FooterImage,
  FooterLeft,
  FooterLogo,
  FooterNav,
  FooterWrapper,
  NavFooterTitle,
} from "./styled";
import footer_img from "../../assets/Footer/footer_img.png";
import footer_logo from "../../assets/Footer/footer_logo.png";
import { Link } from "react-router-dom";
import CommunityAction from "./Action";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterContent>
          <FooterLeft>
            <FooterLogo>
              <img src={footer_logo} alt="logo" />
            </FooterLogo>
            <p>
              POONG is a Master Chef in the AVAX. Cooking the Avalanche Culture
              with community noodle from the talent of Poong
            </p>
          </FooterLeft>
          <FooterNav>
            {navData.map((item, index) => {
              return (
                <div key={index}>
                  <NavFooterTitle>{item.title}</NavFooterTitle>
                  <ul>
                    {item.navLinks.map((links, i) => {
                      return (
                        <li key={i}>
                          {!links.external ? (
                            <Link to={links.href}>{links.title}</Link>
                          ) : (
                            <CommunityAction />
                          )}
                        </li>
                      );
                    })}   
                  </ul>
                </div>
              );
            })}
          </FooterNav>
        </FooterContent>
        <FooterCopyRight>poong@2024</FooterCopyRight>
      </FooterWrapper>
      <FooterImage>
        <img src={footer_img} alt="img" />
      </FooterImage>
    </FooterContainer>
  );
};

const navData = [
  {
    title: "products",
    navLinks: [
      {
        id: 1,
        title: "poong swap",
        href: "#",
        external: false,
      },
    ],
  },
  {
    title: "poong token",
    navLinks: [
      {
        id: 1,
        title: "Coingecko",
        href: "#",
        external: false,
      },
      {
        id: 2,
        title: "Coinmarketcap",
        href: "#",
        external: false,
      },
    ],
  },
  {
    title: "poong community",
    navLinks: [
      {
        id: 1,
        title: "",
        href: "#",
        external: true,
      },
      {
        id: 2,
        title: "",
        href: "#",
        external: false,
      },
    ],
  },
];

export default Footer;
