import styled from "styled-components";
import kick from "../../../assets/GooseKick/kick.png";

export const GooseKickContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 33.2%;
  max-width: 360px;
  left: 0px;
  & > figure {
    width: 100%;
    img {
      width: 100%;
      &:first-child {
        position: absolute;
        width: 100%;
        top: 0px;
        z-index: -1;
        transform: translateX(25%) translateY(-20%);
      }
    }
  }
  @keyframes rorateKick {
    0% {
      transform: scale(0) rotate(0);
    }
    25% {
      transform: scale(1) rotate(360deg);
    }
    50% {
      transform: scale(1) rotate(360deg);
    }
    100% {
      transform: scale(1) rotate(360deg);
    }
  }
  @media screen and (max-width: 767px) {
    /* & > figure {
      width: 300px;
      &::before {
        top: 180px;
        left: 10px;
      }
      img {
        width: 100%;
      }
    } */
  }
`;
export const KickTabs = styled.div`
  &.fadeCoins {
    span {
      &:nth-child(1) {
        animation: kickStart 3s linear infinite;
        animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation: kickStart_2 3s linear infinite;
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation: kickStart_3 3s linear infinite;
        animation-delay: 0.1s;
      }
      &:nth-child(4) {
        animation: kickStart_4 3s linear infinite;
        animation-delay: 0.1s;
      }
    }
  }
  span {
    position: absolute;
    bottom: 90px;
    left: -80px;
    z-index: 5;
    opacity: 0;
  }
  @keyframes kickStart {
    0% {
      transform: translate(0);
      opacity: 0;
    }
    1% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1) translate(-500%, -100%);
    }
    51% {
      opacity: 0;
    }
    100% {
      transform: translate(0);
    }
  }
  @keyframes kickStart_2 {
    0% {
      transform: translate(0);
      opacity: 0;
    }
    1% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1) translate(-500%, -300%);
    }
    51% {
      opacity: 0;
    }
    100% {
      transform: translate(0);
    }
  }
  @keyframes kickStart_3 {
    0% {
      transform: translate(0);
      opacity: 0;
    }
    1% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1) translate(-500%, -500%);
    }
    51% {
      opacity: 0;
    }
    100% {
      transform: translate(0);
    }
  }
  @keyframes kickStart_4 {
    0% {
      transform: translate(0);
      opacity: 0;
    }
    1% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1) translate(-500%, -700%);
    }
    51% {
      opacity: 0;
    }
    100% {
      transform: translate(0);
    }
  }
`;
