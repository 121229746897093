import React from "react";
import {
  SubTitleCommon,
  TitleCommonContainer,
  TitleCommonMain,
} from "./styled";

const TitleCommon = ({ title, subTitle, titleColor, subTitleColor }: any) => {
  return (
    <TitleCommonContainer>
      <SubTitleCommon subTitleColor={subTitleColor}>{subTitle}</SubTitleCommon>
      <TitleCommonMain titleColor={titleColor}>{title}</TitleCommonMain>
    </TitleCommonContainer>
  );
};

export default TitleCommon;
