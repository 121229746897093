import styled from "styled-components";
import { TitleCommonContainer } from "../../../components/Common/Title/styled";
import { BlockLeft, ContentBlock } from "../HowToBuy/styled";

export const GrowWaveWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
export const GrowContainer = styled.div`
  position: relative;
  background-color: #ffc715;
  height: 980px;
  padding-top: 30px;
  ${TitleCommonContainer} {
    margin-bottom: 25px;
  }
  @media screen and (max-width: 1023px) {
    /* overflow: hidden; */
    height: auto;
  }
`;
export const GrowWrapper = styled.div`
  position: relative;
  z-index: 5;
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 15px;
  ${ContentBlock} {
    min-height: unset;
  }
  ${BlockLeft} {
    p {
      max-width: unset;
    }
  }
`;
export const GrowWaveSVG = styled.svg`
  position: absolute;
  top: -100px;
`;
export const GrowContent = styled.div`
  /* max-width: 562px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  figure {
    width: calc(100% - 600px);
    min-width: calc(100% - 600px);
    aspect-ratio: 568 / 518;
    position: relative;
    img{
      position: absolute;
      width: 115.9%;
      left: 0px;
      top: 0px;
    }
  }
  @media screen and (max-width: 1023px) {
    /* display: block; */
    margin: 0 auto;
    flex-direction: column;
    & > div {
      margin-bottom: 20px;
    }
    figure {
      width: 100%;
      max-height: 100%;
      max-width: 568px;
      img{
        max-height: 100%;
        width: auto;
      }

    }
  }
  @media screen and (max-width: 767px) {
    padding: 0 15px;
    figure {
      /* bottom: 0;
      width: 375px;
      right: 50%;
      margin-right: -187px;
      img {
        width: 100%;
      } */
      img {
        width: 100%;
      }
    }
  }
`;
