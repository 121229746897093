import { GrowWaveSVG, GrowWaveWrapper } from "../styled";

const GrowWave = ({background}:any) => {
  return (
    <GrowWaveWrapper>
      <GrowWaveSVG
        width="100%"
        height="400px"
        viewBox="0 0 1000 1000"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        overflow="auto"
        shape-rendering="auto"
        fill="transparent"
      >
        <defs>
          <path
            id="wavepath"
            d="M 0 2000 0 500 Q 27 402 54 500 t 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0 54 0  v1000 z"
          />
          <path id="motionpath" d="M -108 0 0 0" />
        </defs>
        <g>
          <use xlinkHref="#wavepath" y="-400" fill={background}>
            <animateMotion dur="5s" repeatCount="indefinite">
              <mpath xlinkHref="#motionpath" />
            </animateMotion>
          </use>
        </g>
      </GrowWaveSVG>
    </GrowWaveWrapper>
  );
};

export default GrowWave;
