import { GooseKickContainer, KickTabs } from "./styled";
import keyvisual_main from "../../../assets/Home/Keyvisual/keyvisual_main.png";
import coin_1 from "../../../assets/GooseKick/coin_1.png";
import coin_2 from "../../../assets/GooseKick/coin_2.png";
import coin_3 from "../../../assets/GooseKick/coin_3.png";
import coin_4 from "../../../assets/GooseKick/coin_4.png";
import { useEffect, useState } from "react";
import light from '../../../assets/Home/Keyvisual/light.png';

const GooseKick = () => {
  const [fadeCoins, setFadeCoins] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFadeCoins(true);
    }, 100);
  }, []);

  return (
    <GooseKickContainer>
      {/* <KickTabs className={fadeCoins ? "fadeCoins" : ""}>
        <span>
          <img src={coin_1} alt="coin" />
        </span>
        <span>
          <img src={coin_2} alt="coin" />
        </span>
        <span>
          <img src={coin_3} alt="coin" />
        </span>
        <span>
          <img src={coin_4} alt="coin" />
        </span>
      </KickTabs> */}
      <figure>
        <img src={light} alt="" />
        <img className="main" src={keyvisual_main} alt="main" />
      </figure>
    </GooseKickContainer>
  );
};

export default GooseKick;
