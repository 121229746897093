import TitleCommon from "../../../components/Common/Title";
import {
  BlockLeft,
  ContentBlock,
  ContentLeft,
  HowToBuyContainer,
  HowToBuyContent,
  HowToBuyWrapper,
} from "./styled";
import howtobuy_img from "../../../assets/Home/HowToBuy/howtobuy_img.png";
import howtobuy_ic_1 from "../../../assets/Home/HowToBuy/howtobuy_ic_1.png";
import howtobuy_ic_2 from "../../../assets/Home/HowToBuy/howtobuy_ic_2.png";
import howtobuy_ic_3 from "../../../assets/Home/HowToBuy/howtobuy_ic_3.png";
import howtobuy_ic_4 from "../../../assets/Home/HowToBuy/howtobuy_ic_4.png";

const HowToBuy = () => {
  return (
    <HowToBuyContainer id="howtobuy">
      <HowToBuyWrapper>
        <TitleCommon
          title="HOW TO BUY"
          subTitle="Save the world"
          titleColor="#7EC900"
          subTitleColor="#000"
        />
        <HowToBuyContent>
          <ContentLeft>
            <ContentBlock>
              <BlockLeft>
                <h3>Connect wallet</h3>
                <p>
                  Download MetaMask or your preferred wallet from the App Store
                  or Google Play Store. For desktop users, visit metamask.io and
                  download the Google Chrome extension.
                </p>
              </BlockLeft>
              <figure>
                <img src={howtobuy_ic_1} alt="icon" />
              </figure>
            </ContentBlock>
            <ContentBlock>
              <BlockLeft>
                <h3>Get some $AVAX</h3>
                <p>
                  Ensure you have $AVAX in your wallet. If you don’t have any
                  $AVAX, you can purchase directly on MetaMask, transfer from
                  another wallet or buy on another exchange and send it to your
                  wallet
                </p>
              </BlockLeft>
              <figure>
                <img src={howtobuy_ic_4} alt="icon" />
              </figure>
            </ContentBlock>
            <ContentBlock>
              <BlockLeft>
                <h3>Access TraderJoe</h3>
                <p>
                  Go to{" "}
                  <a
                    href="https://traderjoexyz.com/avalanche/trade"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://traderjoexyz.com/avalanche/trade
                  </a>{" "}
                  in google chrome or on the browser inside your Metamask app.
                  Connect your wallet. Select chain “Avalanche C-Chain (43114)”.
                  Paste the $POONG token address into the TraderJoe, select
                  $POONG, and confirm. When Metamask prompts you for a wallet
                  signature, sign.
                </p>
              </BlockLeft>
              <figure>
                <img src={howtobuy_ic_3} alt="icon" />
              </figure>
            </ContentBlock>
            <ContentBlock>
              <BlockLeft>
                <h3>Switch $AVAX for $POONG</h3>
                <p>
                  Switch $AVAX for $POONG. We have NO taxes, so set Slippage to
                  any ratio you want and then click buy.
                </p>
              </BlockLeft>
              <figure>
                <img src={howtobuy_ic_2} alt="icon" />
              </figure>
            </ContentBlock>
          </ContentLeft>
          <figure>
            <img src={howtobuy_img} alt="howtobuy_img" />
          </figure>
        </HowToBuyContent>
      </HowToBuyWrapper>
    </HowToBuyContainer>
  );
};

export default HowToBuy;
