import styled from "styled-components";
import footer_decor from "../../assets/Footer/footer_decor.png";
import goose_mount from "../../assets/Footer/goose_mount.png";
import goose_mount_active from "../../assets/Footer/goose_mount_active.png";
import eye_active from "../../assets/Footer/eye_active.png";

export const FooterContainer = styled.div`
  position: relative;
  z-index: 5;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(${footer_decor}) no-repeat center / 100% auto;
    width: 490px;
    height: 216px;
  }
  @media screen and (max-width: 767px) {
    &::before {
      content: none;
    }
  }
`;
export const FooterWrapper = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 40px 10px;
  min-height: 370px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1023px) {
    padding-bottom: 200px;
  }
`;
export const FooterContent = styled.div`
  margin-bottom: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    display: block;
  }
`;
export const FooterImage = styled.div`
  position: absolute;
  right: -50px;
  bottom: -40px;
  z-index: -1;
  img {
    max-width: 413px;
  }
  @media screen and (max-width: 767px) {
    width: 300px;
    left: 0;
    right: unset;
    img {
      width: 100%;
    }
  }
`;
export const FooterLogo = styled.div`
  @media screen and (max-width: 1023px) {
    width: 154px;
    margin-bottom: 10px;
    img {
      width: 100%;
    }
  }
`;
export const FooterCopyRight = styled.small`
  display: block;
  text-align: center;
  color: #b5b5b5;
`;
export const FooterLeft = styled.div`
  width: 400px;
  & > p {
    color: #000;
    font-size: 20px;
    line-height: 120%;
  }
  @media screen and (max-width: 1023px) {
    width: auto;
    margin-bottom: 20px;
  }
`;
export const FooterActions = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 23.67%;
  &::before,
  &::after {
    content: "";
    position: absolute;
    background: no-repeat center / 100% auto;
    transition: all 0.5s;
    left: 4px;
  }
  &::before {
    width: 100%;
    aspect-ratio: 92/42;
    bottom: -0px;
    transform: translateY(100%);
    background-image: url(${goose_mount});
  }
  &::after {
    width: 80%;
    aspect-ratio: 80/64;
    transform: translateY(100%);
    opacity: 0;
    background-image: url(${goose_mount_active});
  }
  &.mountState {
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44%;
    aspect-ratio: 1/1;
    background-color: #ff7a00;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 2.5px 1px 0px 0px #000;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background: url(${eye_active}) no-repeat center / 100% 100%;
      transform: translate(1px, 1px);
      box-shadow: unset;
    }
    a {
      position: relative;
      top: -4px;
      left: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 21px;
      height: 25px;
      background-color: #fff;
      border-radius: 50%;
    }
  }
`;
export const FooterNav = styled.div`
  display: flex;
  gap: 70px;
  ul {
    & > li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      ${FooterActions}{
        width: 114px;
      }
      a {
        color: #808080;
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    margin-bottom: 100px;
    gap: 30px;
  }
`;
export const NavFooterTitle = styled.p`
  margin-bottom: 15px;
  color: #ff7a00;
  text-shadow: 0px 2px 0px #000;
  -webkit-text-stroke-width: 0.45px;
  -webkit-text-stroke-color: #fff;
  font-size: 20px;
`;

