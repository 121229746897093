import { NoodlesChoptickContainer, NoodlesChoptickWrapper } from "./styled";
import { motion } from "framer-motion";

const NoodlesChoptick = ({ chopsticksTranslate, trackMouseY }: any) => {
  return (
    <NoodlesChoptickContainer>
      <NoodlesChoptickWrapper>
        <motion.span
          style={{
            transform: chopsticksTranslate
              ? `translateX(${chopsticksTranslate * 2}%)`
              : `translateX(-${trackMouseY}px)`,
          }}
          className="flavors"
        ></motion.span>
        <motion.span className="bowl"></motion.span>
        <motion.span className="noodles"></motion.span>
        <motion.span
          style={{
            transform: chopsticksTranslate
              ? `translateX(${chopsticksTranslate}%)`
              : `translateX(-${trackMouseY}px)`,
          }}
          className="chopsticks"
        ></motion.span>
      </NoodlesChoptickWrapper>
    </NoodlesChoptickContainer>
  );
};

export default NoodlesChoptick;
