import styled from "styled-components";
import { TitleCommonContainer } from "../../../components/Common/Title/styled";

export const HowToBuyContainer = styled.div`
  position: relative;
  z-index: 5;
  background: #ffc715;
  padding: 35px 10px 120px;
  ${TitleCommonContainer} {
    margin-bottom: 70px;
  }
  @media screen and (max-width: 1023px) {
    overflow: hidden;
    padding-bottom: 30px;
    ${TitleCommonContainer} {
      margin-bottom: 30px;
    }
  }
`;
export const HowToBuyWrapper = styled.div`
  max-width: 1120px;
  margin: 0 auto;
`;
export const HowToBuyContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
  & > figure {
    position: absolute;
    right: -170px;
  }
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    max-width: 700px;
    margin: 0 auto;
    & > figure {
      width: 300px;
      margin: 0 auto;
      position: relative;
      right: 0;
      img {
        width: 100%;
      }
    }
  }
`;
export const ContentLeft = styled.div`
  max-width: 904px;
  width: 80.7%;
  @media only screen and (max-width: 768px) {
    width: 85%;
  }
`;
export const ContentBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  border-radius: 17px;
  background: #fff;
  box-shadow: 2px 3px 0px 0px rgba(0, 0, 0, 0.7);
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  &:not(:first-child) {
    figure {
      width: 104px;
      height: 104px;
      flex-shrink: 0;
      img {
        width: 100%;
      }
    }
  }
  &:first-child {
    figure {
      position: absolute;
      height: 140%;
      aspect-ratio: 1/1.4;
      left: calc(87.5% + 12px);
      img{
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  @media screen and (max-width: 1023px) {
    padding: 15px;
    &:first-child {
      figure {
        position: relative;
        top: 0;
        right: 0;
        left: unset;
        img {
          width: 100%;
        }
      }
    }
  }
`;
export const BlockLeft = styled.div`
  max-width: 87.5%;
  h3 {
    color: #ff7a00;
    text-shadow: 0px 2px 0px #000;
    -webkit-text-stroke-width: 0.2px;
    -webkit-text-stroke-color: #fff;
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 10px;
  }
  p {
    color: #323232;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 10px;
    a {
      color: #e32f3b;
      word-break: break-all;
    }
  }
  & > ul {
    padding-left: 15px;
    & > li {
      color: #323232;
      list-style-type: disc;
      line-height: 130%;
    }
  }
  @media screen and (max-width: 1023px) {
    p {
      font-size: 14px;
    }
  }
`;
export const BlockButtons = styled.div`
  display: flex;
  gap: 10px;
`;
