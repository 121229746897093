import { Link } from "react-router-dom";
import styled from "styled-components";

export const ButtonCommon = styled(Link)<{ color: string; background: string }>`
  border-radius: 15px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  box-shadow: 2px 4px 0px 0px #000;
  padding: 20px 28px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translate(3px, 3px);
    box-shadow: unset;
  }
`;
