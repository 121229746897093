import styled from "styled-components";
import bowl from "../../../assets/NoodlesChopsticks/bowl.png";
import chopsticks from "../../../assets/NoodlesChopsticks/chopsticks.png";
import noodles from "../../../assets/NoodlesChopsticks/noodles.png";
import flavors from "../../../assets/NoodlesChopsticks/flavors.png";

export const NoodlesChoptickContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: -240px;
  z-index: 5;
  width: 100%;
  height: 580px;
`;
export const NoodlesChoptickWrapper = styled.div`
  width: 100%;
  height: 580px;
  & > span {
    position: absolute;
    display: block;
    background: no-repeat center / 100% 100%;
    &.flavors {
      left: -300px;
      top: 100px;
      background-image: url(${flavors});
      z-index: 3;
      width: 340px;
      height: 206px;
    }
    &.bowl {
      top: 0;
      left: 0;
      width: 200px;
      height: 434px;
      background-image: url(${bowl});
      z-index: 3;
    }
    &.noodles {
      left: 40px;
      top: 10px;
      width: 200px;
      height: 434px;
      background-image: url(${noodles});
      z-index: 1;
    }
    &.chopsticks {
      top: 30px;
      left: -50%;
      width: 100%;
      height: 500px;
      background-image: url(${chopsticks});
      z-index: 2;
    }
  }
`;
